.jumbotron {
	height: 100vh !important;
	width: 100vw !important;
	overflow: hidden !important;
	margin-bottom: 0px !important;
}

.jumbotron-building {
	background-image: linear-gradient(to bottom, rgba(10, 10, 10, 0.5), rgba(0, 0, 0, 0.986)),
		url('../../images/people_church_building.jpg');
	background-color: black;
	height: 100vh;
	padding: 0;
	margin: 0;
	background-size: cover;
	background-attachment: static;
	background-position: center;
	background-repeat: no-repeat;
	overflow: hidden;
	animation: fadeIn ease 1s;
	-webkit-animation: fadeIn ease 1s;
	-moz-animation: fadeIn ease 1s;
	-o-animation: fadeIn ease 1s;
	-ms-animation: fadeIn ease 1s;
}

@media only screen and (max-width: 1200px) {
	.animated-images-container {
		height: 100%;
		width: 100%;
		transform: translate(0%, 25%);
	}
}

@media only screen and (max-width: 500px) {
	.animated-images-container {
		height: 100%;
		width: 100%;
		transform: translate(0%, 22%);
	}
}

@media only screen and (max-width: 300px) {
	.animated-images-container {
		height: 100%;
		width: 100%;
		transform: translate(0%, 20%);
	}
}
