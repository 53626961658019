.donation-table-container {
	height: 100%;
}

table td,
table th {
	padding: 0px !important;
	border-top: 0px !important;
	width: calc(100vw / 100);
	height: calc(100vh / 100);
	background-color: black;
}

.table {
	height: 100%;
	margin: 0px !important;
}

.table-responsive-xl {
	padding: 0 !important;
}

.donation-made {
	background-color: transparent !important;
	transition: opacity 500ms ease-in;
}

.amount-container {
	position: absolute;
	bottom: 3%;
	right: 1%;
	transform: translate(-5%, -5%);
	color: white;
	animation: fadeIn ease 5s;
	-webkit-animation: fadeIn ease 5s;
	-moz-animation: fadeIn ease 5s;
	-o-animation: fadeIn ease 5s;
	-ms-animation: fadeIn ease 5s;

	span {
		line-height: 10px;
		margin: 12px;
		display: block;

		p {
			font-weight: 400;
			letter-spacing: 2px;
			font-size: 14px;
			text-align: right;
			margin: 0;
		}
	}
}

@media only screen and (max-width: 900px) {
	.amount-container {
		bottom: 20%;
		right: 50%;
		transform: translate(50%, 50%);
		width: 100%;

		span {
			line-height: 8px;

			p {
				text-align: center;
				letter-spacing: 1px;
				font-size: 12px;
			}
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-ms-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
