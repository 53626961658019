.progress-bar-container {
	width: 100%;
	margin-top: 5px;

	.bar {
		border: 1px solid black;
		margin-bottom: 2px;
		background: white;
		height: 20px;
		width: 100%;
		float: left;

		.bar-filled {
			background: rgb(22, 206, 83);
			height: 100%;
		}
	}

	.percentage p {
		text-transform: uppercase;
		text-align: left;
		margin: 0 auto;
		font-size: 14px;
	}
}

@media only screen and (max-width: 800px) {
	.progress-bar-container {
		.bar {
			height: 8px;
		}

		.percentage p {
			font-size: 10px;
			margin-bottom: 5px;
		}
	}
}
