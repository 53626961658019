.thank-you {
	.thank-you-container {
		padding: 80px;
		margin: 0;
		position: absolute;
		top: 50%;
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	.header {
		font-weight: 600;
		font-size: 32px;
		text-align: left;
		margin-bottom: 5px;
	}

	p {
		text-align: left;
		font-size: 20px;
		font-weight: 300;
		text-transform: uppercase;
	}

	.contribution-email {
		font-size: 16px;
		text-align: left;
		font-weight: 300;
		color: gray;
	}
}

@media only screen and (max-width: 1199px) {
	.thank-you {
		.thank-you-container {
			padding: 20px;
			padding-right: 50px;
			text-align: left;

			.header {
				font-size: 26px;
				margin-bottom: 5px;
			}

			p {
				font-size: 14px;
				margin-bottom: 5px;
			}
			.contribution-email {
				font-size: 12px;
			}
		}
	}
}
