.pulsating-circle-container {
	height: 100%;
	width: 100%;
	margin: 0 auto;
	position: inherit;
	z-index: 999;

	.pulsating-circle-container-inner {
		width: 60%;
		height: 60%;
		margin: auto;
		display: inline-block;
		border-radius: 40%;
		transform: translate(40%, 40%);
		cursor: pointer;
	}
}

.production-container {
	position: absolute;
	left: 47%;
	bottom: 18%;

	.production-container-tiles {
		opacity: 0;
		transition: 3s;
		width: 425px;
		height: 425px;
		margin: auto;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;

		.production-container-tile {
			width: 85px;
			height: 85px;
			float: left;
		}
	}

	&:hover .production-container-tiles {
		margin: 0 auto;
		padding: 0;
		opacity: 1;
	}
}

.structural-container {
	position: absolute;
	left: 20%;
	bottom: 9%;

	.structural-container-tiles {
		opacity: 0;
		transition: 3s;
		width: 425px;
		height: 425px;
		margin: auto;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;

		.structural-container-tile {
			width: 85px;
			height: 85px;
			float: left;
		}
	}

	&:hover .structural-container-tiles {
		margin: 0 auto;
		padding: 0;
		opacity: 1;
	}
}

.fixtures-container {
	position: absolute;
	top: 5%;
	left: 25%;

	.fixtures-container-tiles {
		opacity: 0;
		transition: 3s;
		width: 425px;
		height: 425px;
		margin: auto;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;

		.fixtures-container-tile {
			width: 85px;
			height: 85px;
			float: left;
		}
	}

	&:hover .fixtures-container-tiles {
		margin: 0 auto;
		padding: 0;
		opacity: 1;
	}
}

.kids-container {
	position: absolute;
	bottom: 27%;
	right: 6%;

	.kids-container-tiles {
		opacity: 0;
		transition: 3s;
		width: 425px;
		height: 425px;
		margin: auto;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;

		.kids-container-tile {
			width: 85px;
			height: 85px;
			float: left;
		}
	}

	&:hover .kids-container-tiles {
		margin: 0 auto;
		padding: 0;
		opacity: 1;
	}
}

.furniture-container {
	position: absolute;
	top: 2%;
	right: 17%;

	.furniture-container-tiles {
		opacity: 0;
		transition: 3s;
		width: 425px;
		height: 425px;
		margin: auto;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;

		.furniture-container-tile {
			width: 85px;
			height: 85px;
			float: left;
		}
	}

	&:hover .furniture-container-tiles {
		margin: 0 auto;
		padding: 0;
		opacity: 1;
	}
}

.exterior-container {
	position: absolute;
	top: 25%;
	left: 5%;

	.exterior-container-tiles {
		opacity: 0;
		transition: 3s;
		width: 425px;
		height: 425px;
		margin: auto;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;

		.exterior-container-tile {
			width: 85px;
			height: 85px;
			float: left;
		}
	}

	&:hover .exterior-container-tiles {
		margin: 0 auto;
		padding: 0;
		opacity: 1;
	}
}

.image-button {
	width: 80%;
	height: 50px;
	background-color: black;
	color: white;
	margin: 0 auto;
	margin-bottom: 15px;
	border-radius: 2px;

	p {
		text-transform: uppercase;
		font-weight: 600;
		font-size: 20px;
		letter-spacing: 2px;
		text-align: center;
		line-height: 50px;
	}

	&:hover {
		cursor: pointer;
		transition: 0.5s all;
		color: black;
		background-color: white !important;
	}
}

.production-container-responsive,
.exterior-container-responsive,
.furniture-container-responsive,
.kids-container-responsive,
.fixtures-container-responsive,
.structural-container-responsive {
	display: none;
}

@media only screen and (max-width: 1200px) {
	.production-container,
	.exterior-container,
	.furniture-container,
	.kids-container,
	.fixtures-container,
	.structural-container {
		display: none;
	}

	.production-container-responsive,
	.exterior-container-responsive,
	.furniture-container-responsive,
	.kids-container-responsive,
	.fixtures-container-responsive,
	.structural-container-responsive {
		animation: fadeIn ease 1s;
		-webkit-animation: fadeIn ease 1s;
		-moz-animation: fadeIn ease 1s;
		-o-animation: fadeIn ease 1s;
		-ms-animation: fadeIn ease 1s;
		display: block !important;
	}
}

@media only screen and (max-width: 600px) {
	.production-container-responsive,
	.exterior-container-responsive,
	.furniture-container-responsive,
	.kids-container-responsive,
	.fixtures-container-responsive,
	.structural-container-responsive {
		cursor: pointer;
		p {
			font-size: 14px;
		}
	}
}

@media only screen and (max-width: 400px) {
	.production-container-responsive,
	.exterior-container-responsive,
	.furniture-container-responsive,
	.kids-container-responsive,
	.fixtures-container-responsive,
	.structural-container-responsive {
		p {
			font-size: 12px;
		}
	}

	.structural-container-responsive {
		p {
			padding-top: 10px;
			line-height: 12px !important;
		}
	}

	.image-button {
		height: 30px;

		p {
			text-transform: uppercase;
			font-weight: 600;
			font-size: 10px;
			line-height: 30px;
		}

		&:hover {
			cursor: pointer;
			transition: 0.5s all;
			color: black;
			background-color: white !important;
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-ms-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
