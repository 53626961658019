.main-logo {
	top: 0;
	position: absolute;
	left: 50%;
	margin-top: 30px;
	margin-left: -40px;
	text-align: center;

	img {
		max-width: 50px;
		margin: auto;
	}
}

.countdown {
	position: absolute;
	top: 3%;
	right: 1%;
	color: white;
	animation: fadeIn ease 5s;
	-webkit-animation: fadeIn ease 5s;
	-moz-animation: fadeIn ease 5s;
	-o-animation: fadeIn ease 5s;
	-ms-animation: fadeIn ease 5s;

	span {
		line-height: 10px;
		margin: 12px;
		display: block;

		p {
			font-weight: 600;
			letter-spacing: 2px;
			font-size: 24px;
			text-align: right;
			margin: 0;
		}
	}
}

.progress-bar-percentage {
	width: 100%;
	text-align: center;
	font-size: 16px;
	position: absolute;
	margin: 0 auto;
	color: white;
	font-weight: 600;
	z-index: 9999;
	bottom: 0;
}

.root-progress-bar {
	bottom: 0;
	position: absolute;
	height: 25px;
	width: 100%;

	.root-progress-bar-inner {
		height: 100%;
		background: rgb(22, 206, 83);
	}
}

.overlay-elements {
	position: absolute;
	left: 4%;
	bottom: 5%;
	width: 600px;

	.overlay-button {
		height: 60px;
		background-color: black;
		border: 2px solid white;
		padding: 5px;
		border-radius: 3px;
		color: white;

		&:hover {
			cursor: pointer;
		}

		img {
			max-width: 100%;
			max-height: 100%;
			margin-left: 5px;
			float: left;
		}

		p {
			padding-top: 5px;
			width: 80%;
			float: right;
			text-transform: uppercase;
			margin: 0 auto;
			font-weight: 600;
			font-size: 12px;
			line-height: 12px;
		}
	}

	.pixel-overlay-button {
		width: 50%;

		.overlay-button-pixel-page-text {
			font-size: 16px;
			font-weight: 300;
			width: 70%;
			line-height: 18px;
		}

		.overlay-button-pixel-page-image {
			margin-left: 15px;
		}
	}

	.left {
		float: left;
		width: 49%;
		p {
			padding-top: 10px;
		}
	}

	.right {
		float: right;
		width: 49%;
	}
}

.building-icon {
	width: 100px;
}

@media only screen and (max-width: 900px) {
	.overlay-elements {
		left: 0;
		bottom: 0;
		padding: 10px 5vw 5vh 5vw;
		width: 100%;
	}

	.main-logo {
		margin-left: -30px;
	}

	.countdown {
		top: 100px;
		margin: 0 auto;
		width: 100%;

		span {
			width: 50%;
			line-height: 10px;
			margin: 0 auto;
			display: block;

			p {
				font-size: 14px;
				text-align: center;
			}
		}
	}
}

@media only screen and (max-width: 900px) and (min-width: 451px) {
	.overlay-elements {
		.overlay-button {
			p {
				font-weight: 300;
				font-size: 9px;
				line-height: 12px;
				width: 70%;
			}

			.overlay-button-pixel-page-text {
				padding-top: 12px;
				font-size: 14px;
				font-weight: 300;
				width: 70%;
			}

			.overlay-button-pixel-page-image {
				margin-left: 50px;
			}
		}

		.pixel-overlay-button {
			width: 100%;
		}
	}
}

@media only screen and (max-width: 450px) and (min-width: 425px) {
	.overlay-elements {
		.overlay-button {
			p {
				padding: 0;
				font-size: 8px;
				font-weight: 300;
				line-height: 9px;
				width: 70%;
			}
			.overlay-button-pixel-page-text {
				padding-top: 14px;
				font-size: 12px;
				font-weight: 300;
				width: 70%;
			}

			.overlay-button-pixel-page-image {
				margin-left: 40px;
			}
		}
		.pixel-overlay-button {
			width: 100%;
		}
	}
}

@media only screen and (max-width: 424px) {
	.overlay-elements {
		.left {
			p {
				padding-top: 6px !important;
			}
		}

		.overlay-button {
			p {
				padding-top: 4px;
				font-size: 10px;
				font-weight: 300;
				line-height: 9px;
				width: 70%;
			}

			img {
				max-height: 70%;
				margin-top: 6px;
			}

			.overlay-button-pixel-page-text {
				padding-top: 14px;
				font-size: 12px;
				font-weight: 300;
				width: 70%;
			}

			.overlay-button-pixel-page-image {
				margin-left: 40px;
			}
		}
		.pixel-overlay-button {
			width: 100%;
		}
	}
}
