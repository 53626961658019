.payment-modal-header {
	color: black;
	font-size: 28px;
	letter-spacing: 2px;
	font-weight: 600;
	text-transform: uppercase;
	margin: 0 auto;
	margin-bottom: 10px;
	text-align: left;
}

.payment-modal-left {
	width: 60%;
	float: left;
}

.payment-image {
	width: 100%;
	margin-bottom: 10px;
	float: left;
}

.payment-modal-right {
	padding-top: 40px;
	width: 40%;
	float: right;
	margin: 0 auto;
	padding-left: 20px;
	height: 100%;
}

.donation-line-item {
	margin: 0 auto;
	text-align: left;

	&:after {
		content: '';
		display: table;
		clear: both;
	}

	.donation-line-title {
		float: left;
		padding: 5px;
		width: 60%;

		h3 {
			font-weight: 600;
			font-size: 12px;
			margin: 0;
		}

		.donation-line-price {
			font-weight: 300;
			font-size: 14px;
			margin: 0 auto;

			i {
				padding-left: 5px;
				font-weight: 300;
				font-size: 8px;
				color: black;
				margin: 0 auto;
			}
		}

		.donation-line-price-give {
			font-weight: 600;
			font-size: 18px;
			margin: 0 auto;
		}

		.category-amount-funded {
			font-weight: 300;
			font-size: 10px;
			color: black;
			margin: 0 auto;
		}
	}

	.amount-selection {
		float: left;
		padding: 5px 0px 5px 0px;
		width: 20%;
	}

	select {
		width: 77%;
		border: 2px solid black;
		padding-left: 5px;
		color: black;
		border-radius: 0px;
		outline: none;
	}

	.contribute-button {
		float: right;
		text-align: right;
		padding: 5px 0px 5px 0px;
		font-size: 14px;
		width: 22%;
		font-weight: 600;
		margin-left: -10px;
	}
}

.payment-divider {
	margin: 0 auto;
	margin-top: 10px;
	margin-bottom: 10px;
	height: 1px;
	background-color: rgb(107, 107, 107);
}

.payment-total {
	margin: 0 auto;
	margin-top: 5px;

	h3 {
		margin-top: 3px;
		margin-bottom: 10px;
		font-size: 16px;
		font-weight: 600;
	}

	.payment-total-text {
		float: left;
		text-align: left;
		width: 50%;
	}

	.payment-total-amount {
		float: right;
		width: 50%;
		text-align: right;
	}
}

.payment-contribute-button {
	width: 100%;
	padding: 5px;
	margin: 0 auto;

	button {
		width: 100%;
		height: 40px;
		padding: 5px;
		font-size: 12px;
		float: right;
		outline: none;
		font-weight: 600;
		letter-spacing: 2px;
		color: white;
		border-radius: 3px;
		background-color: black;
		border: 2px solid black;
		transition: 0.2s background-color linear, 0.2s color linear;

		&:hover {
			color: black;
			background-color: white !important;
			border: 2px solid black !important;
		}

		&:active,
		&:focus {
			outline: none;
		}

		&:disabled {
			background-color: rgba(211, 211, 211, 0.637);
			border: 2px rgba(211, 211, 211, 0.637);

			&:hover {
				cursor: not-allowed;
				color: white;
				background-color: rgba(211, 211, 211, 0.637) !important;
				border: 2px rgba(211, 211, 211, 0.637) !important;
			}
		}
	}
}

.payment-form {
	width: 100%;
	margin: 0 auto;

	.payment-description {
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 600;
		text-align: justify;
		text-justify: inter-word;
		letter-spacing: 1px;
	}

	.payment-name-inputs {
		display: inline-block;
		margin-bottom: -7px;
		width: 100%;
		.payment-name-left {
			width: 49%;
			float: left;
		}
		.payment-name-right {
			width: 49%;
			float: right;
		}
	}

	.payment-form-header {
		text-align: left;
		margin: 0 auto;
		margin-top: 5px;
		font-weight: 600;
		font-size: 12px;
	}

	.payment-input {
		margin: 0 auto;
		background-color: rgba(107, 107, 107, 0.144);
		border-radius: 3px;
		padding: 10px;
		font-size: 14px;
		color: black;
		outline: none;
		border: none;
		width: 100%;
		height: 36px;

		&::placeholder {
			color: #818181;
		}
	}

	.stripe-payment-card {
		margin: 0 auto;
		margin-bottom: 8px;
		background-color: rgba(107, 107, 107, 0.144);
		border-radius: 3px;
		padding: 10px;

		.base {
			font-size: 16px;
			color: black;
			letter-spacing: 0.025em;
			font-family: Montserrat;
			&::placeholder {
				color: #aab7c4;
			}
		}
		.invalid {
			color: #aab7c4;
		}
	}

	.error-message {
		text-align: left;
		margin: 0 auto;
		color: #c23d4b;
		font-size: 10px;
	}

	.payment-submit {
		float: right;
		width: 47%;
		outline: none;
		font-weight: 600;
		letter-spacing: 2px;
		color: white;
		border-radius: 3px;
		background-color: rgb(53, 22, 224);
		border: 2px solid rgb(53, 22, 224);

		&:disabled {
			background-color: rgba(146, 145, 153, 0.377);
			border: 2px solid transparent;
			cursor: not-allowed;
		}

		&:active,
		&:focus {
			outline: none;
		}
	}

	.payment-back {
		float: left;
		width: 47%;
		outline: none;
		font-weight: 600;
		letter-spacing: 2px;
		color: black;
		border-radius: 3px;
		background-color: white;
		border: 2px solid black;

		&:active,
		&:focus {
			outline: none;
		}
	}
}

@media only screen and (max-width: 1199px) {
	.payment-modal-container {
		width: 450px;
		height: 730px;
	}

	.close-modal-button {
		font-size: 40px;
	}

	.payment-modal-header {
		font-size: 18px;
	}

	.payment-description {
		margin-top: 15px;
	}

	.payment-modal-left,
	.payment-modal-right {
		width: 100% !important;
		padding: 0px;
	}
}

@media only screen and (max-width: 500px) {
	.payment-modal-container {
		width: 90%;
		height: 650px;
	}

	.payment-modal-header {
		font-size: 14px;
	}

	.donation-line-item {
		.donation-line-title {
			padding: 0px;
			margin-bottom: 3px;
		}
	}

	.payment-form {
		.payment-description {
			margin-top: 5px;
			font-size: 9px;
		}
		.payment-form-header {
			font-size: 10px;
		}
		.payment-total {
			margin-bottom: 20px !important;
		}
	}
}
