.modal-background {
	cursor: default;
	overflow: hidden;
	position: fixed;
	z-index: 999999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.473);
	animation: fadeIn ease 0.2s;
	-webkit-animation: fadeIn ease 0.2s;
	-moz-animation: fadeIn ease 0.2s;
	-o-animation: fadeIn ease 0.2s;
	-ms-animation: fadeIn ease 0.2s;
}

.modal-container {
	width: 1100px;
	height: 510px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	background-color: white;
	border-radius: 5px;
	text-align: center;
	padding: 20px;
	-webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
	animation: fadeIn ease 0.5s;
	-webkit-animation: fadeIn ease 0.5s;
	-moz-animation: fadeIn ease 0.5s;
	-o-animation: fadeIn ease 0.5s;
	-ms-animation: fadeIn ease 0.5s;
}

.modal-container-give {
	height: 430px !important;
}

.close-modal-button {
	position: absolute;
	top: -5px;
	margin-top: 0px;
	padding: 0px !important;
	font-size: 50px;
	right: 30px;

	&:hover {
		color: rgb(53, 22, 224);
		cursor: pointer;
	}
}

@media only screen and (max-width: 1199px) {
	.modal-container {
		width: 450px;
		height: 780px;
		overflow-y: scroll;
		-ms-overflow-style: none;
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none !important;
		}
	}

	.modal-container-give {
		height: 630px !important;
	}
}

@media only screen and (max-width: 500px) {
	.modal-container {
		width: 100%;
		height: 100%;
	}

	.modal-container-give {
		height: 100%;
	}

	.close-modal-button {
		top: -5px;
		font-size: 40px;
		right: 20px;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-ms-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
