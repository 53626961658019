.jumbotron-pixel {
	background-image: linear-gradient(to bottom, rgba(10, 10, 10, 0.246), rgba(0, 0, 0, 0.986)),
		url('../../images/people_church_building.jpg');
	background-color: black !important;
	height: 100vh;
	padding: 0;
	margin: 0;
	background-size: cover;
	background-attachment: static;
	background-position: center;
	background-repeat: no-repeat;
	overflow: hidden;
	animation: fadeIn ease 5s;
	-webkit-animation: fadeIn ease 5s;
	-moz-animation: fadeIn ease 5s;
	-o-animation: fadeIn ease 5s;
	-ms-animation: fadeIn ease 5s;
}

.jumbotron-pixel-hidden {
	background-color: black;
	height: 100vh;
	padding: 0;
	margin: 0;
	background-size: cover;
	background-attachment: static;
	background-position: center;
	background-repeat: no-repeat;
	overflow: hidden;
}

.generosity-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	font-weight: 700;
	width: 100%;

	.generosity-header {
		font-weight: 700;
		letter-spacing: 2px;
		font-size: 24px;
		color: white;
		animation: fadeIn ease 2s;
		-webkit-animation: fadeIn ease 2s;
		-moz-animation: fadeIn ease 2s;
		-o-animation: fadeIn ease 2s;
		-ms-animation: fadeIn ease 2s;
	}
}

@media only screen and (max-width: 600px) {
	.generosity-header {
		font-size: 18px !important;
		line-height: 25px !important;
		letter-spacing: 2px !important;
		padding: 0px 10px 0px 10px;
	}
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-ms-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
