.pulsating-circle {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 9999;
	z-index: 9999;
	transform: translateX(-50%) translateY(-50%);
	width: 20px;
	height: 20px;

	&:hover {
		cursor: pointer;
	}

	&:before {
		content: '';
		position: relative;
		display: block;
		width: 300%;
		height: 300%;
		box-sizing: border-box;
		margin-left: -100%;
		margin-top: -100%;
		border-radius: 35px;
		background-color: white;
		animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		background-color: white;
		border-radius: 15px;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
		animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
	}
}

@keyframes pulse-ring {
	0% {
		transform: scale(0.25);
	}
	80%,
	100% {
		opacity: 0;
	}
}

@keyframes pulse-dot {
	0% {
		transform: scale(0.8);
	}
	50% {
		transform: scale(1);
	}
	100% {
		transform: scale(0.8);
	}
}
